import {
  Box,
  Button,
  Container,
  GlobalStyles,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { db } from "./firebase";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import img5 from "./img5.png";
import img6 from "./img6.png";
import "./index.css";
import logo from "./logo.png";

const themeLight = createTheme({
  body: {
    backgroundColor: "lightgray",
  },
});

function App() {
  const [formValues, setFormValues] = useState({
    fikirAciklama: "",
    yenilikciYon: "",
    iletisim: "",
  });

  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection("fikirler").add({
      fikirAciklama: formRef.current.fikirAciklama.value,
      yenilikciYon: formRef.current.yenilikciYon.value,
      iletisim: formRef.current.iletisim.value,
    });

    toast.success("Fikriniz başarıyla gönderildi.");

    setFormValues({
      fikirAciklama: "",
      yenilikciYon: "",
      iletisim: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <ThemeProvider theme={themeLight} t>
      <GlobalStyles styles={{ body: { backgroundColor: "#fdfdfd" } }} />
      <Toaster />
      <Box
        sx={{
          // backgroundImage: `url(${bg})`,
          backgroundColor: "#1f2937",
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          padding: 0,
          margin: 0,
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "center",
            gap: "1rem",
            padding: "1rem",
            paddingTop: "4rem",
            paddingBottom: "4rem",
            margin: "2rem",
            borderRadius: ".5rem",
          }}
        >
          <img src={logo} alt="logo" style={{ width: "300px" }} />

          <Typography
            variant="h6"
            style={{
              textAlign: "left",
              fontWeight: "inherit",
              color: "#d1d5db",
            }}
          >
            Fikirlerinizi bizimle paylaşın, birlikte hayata geçirelim.
          </Typography>
          <Container
            style={{ display: "flex", flexDirection: "row", }}
          />
          <form
            ref={formRef}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: "1rem",
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              id="fikir-aciklama"
              name="fikirAciklama"
              label="Fikir açıklaması"
              variant="filled"
              required
              value={formValues.fikirAciklama}
              onChange={handleChange}
              multiline
              rows={3}
              InputProps={{
                sx: {
                  color: "black",
                  background: "#fef2f2",
                  borderRadius: ".5rem",
                  "&:hover": {
                    background: "#fef2f2",
                    color: "black",
                  },
                  "&.Mui-focused": {
                    background: "#fef2f2",
                    color: "black",
                  },
                },
              }}
            />
            <TextField
              id="yenilikci-yon"
              name="yenilikciYon"
              label="Yenilikçi yön"
              variant="filled"
              required
              value={formValues.yenilikciYon}
              onChange={handleChange}
              multiline
              rows={5}
              InputProps={{
                sx: {
                  color: "black",
                  background: "#fef2f2",
                  borderRadius: ".5rem",
                  "&:hover": {
                    background: "#fef2f2",
                    color: "black",
                  },
                  "&.Mui-focused": {
                    background: "#fef2f2",
                    color: "black",
                  },
                },
              }}
            />
            <TextField
              id="iletisim"
              name="iletisim"
              label="İletişim"
              variant="filled"
              value={formValues.iletisim}
              onChange={handleChange}
              type="number"
              InputProps={{
                sx: {
                  color: "black",
                  background: "#fef2f2",
                  borderRadius: ".5rem",
                  "&:hover": {
                    background: "#fef2f2",
                    color: "black",
                  },
                  "&.Mui-focused": {
                    background: "#fef2f2",
                    color: "black",
                  },
                },
              }}
            />
            <Button
              variant="contained"
              style={{ marginTop: "1rem", padding: "1rem" }}
              type="submit"
            >
              Gönder
            </Button>{" "}
            <Button
              variant="outlined"
              style={{ padding: "1rem" }}
              fullWidth
              onClick={() => window.open("https://www.akgunelektrik.com.tr/")}
            >
              Web Sitemizi Ziyaret Edin
            </Button>
          </form>
        </Container>

        <Container
          sx={{
            display: {
              xs: "none",
              md: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
              padding: "2rem",
              width: "100%",
            },
          }}
        >
          <Carousel
            autoPlay
            showThumbs={false}
            labels={false}
            showStatus={false}
            infiniteLoop
          >
            <Container sx={{ height: "75%", width: "75%" }}>
              <img src={img2} alt="img2" className="cover-fill" />
            </Container>
            <Container sx={{ height: "75%", width: "75%" }}>
              <img src={img3} alt="img3" className="cover-fill" />
            </Container>
            <Container sx={{ height: "75%", width: "75%" }}>
              <img src={img4} alt="img4" className="cover-fill" />
            </Container>
            <Container sx={{ height: "75%", width: "75%" }}>
              <img src={img5} alt="img5" className="cover-fill" />
            </Container>
            <Container sx={{ height: "75%", width: "75%" }}>
              <img src={img6} alt="img6" className="cover-fill" />
            </Container>
          </Carousel>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
